import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/builds/Mondaic/infrastructure/website/src/templates/mdx_template.tsx";
import { Embed } from 'semantic-ui-react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Pipeline Monitoring`}</h1>
    <p>{`Salvus efficiently computes wavefields propagating in engineering
components with complex geometries, including those of interest for
nondestructive testing. In this application, we see the scattered
wavefield emitted by an ultrasound transducer array and interacting with
a critical thickness variation of a pipeline. By examining the character
of this scattered wavefield, and combining it with our full-waveform
inversion functionality, Salvus is opening up exciting new paths towards
corrosion mapping and pipeline monitoring.`}</p>
    <Embed id="465480798" source="vimeo" autoPlay={false} placeholder="https://vumbnail.com/465480798.jpg" iframe={{
      allowFullScreen: true
    }} mdxType="Embed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      